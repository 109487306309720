
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import '~jquery-ui/themes/base/all.css';

// STYLE
@import 'template';
@import 'custom'; //customizace laravelu


.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);

}

.submit-buttons{
  display:inline-flex;
  position:fixed;
  top:4.3rem;
  right:5px;
  z-index:9999;
  height:3rem;
  background-color:yellow;
  align-items:center;
  justify-content:center;
  border:white 1px solid;
  border-radius:4px;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);

}
.submit-buttons>*{
  margin: .5rem;

}
