/*
.bg {   background-color: #EDF56E;
.bg1 {  background-color: #F3F8D0;
*/
  #TASK_RECIPIENTS_DIV {
    word-wrap: break-word;
  }

.copy-to-clipboard{
  cursor: copy;
}

.copy-to-clipboard:after{
  font-family: "Font Awesome 5 Free";
  content: "\f0c6";
  font-size: 14px;
  margin-left:5px;
  color:green;
  background-color: transparent;
  display: inline-block;
  position: relative;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
}


.ui-icon-circle-triangle-w {
  border-right:8px solid black;
  border-top:8px transparent solid;
  border-bottom:8px transparent solid;
  border-left:0;

}


.ui-icon-circle-triangle-e {
  border-left:8px solid black;
  border-top:8px transparent solid;
  border-bottom:8px transparent solid;
  border-right:0;

}

.zoom {
  padding: 0px;
  width: 60px;
  transition: transform .2s; /* Animation */  
  background-color: silver;
  height: 40px;
  border-radius: 3px;
}

.zoom:hover {
    transform: scale(3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    position:relative;
    z-index:999;
    display:block;
    -webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
    -moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
    box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
}


  .progress-bar-vertical {
  width: 9px;
  background-color: white !important;
  min-height: 40px;
  display: flex;
  align-items: flex-end;
  margin-right: 20px;
  float: left;
}

.green-border {
  border: 1px #509208 solid;
}


.green-border:hover{
  background-color: #F0FBEA;
}

.red-border {
    border: 2px rgb(131, 15, 15) solid;
}


.red-border:hover{
  background-color: #F0FBEA;
}


.progress-bar-vertical .progress-bar {
  width: 100%;
  height: 0;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
}


.green-box {
  background: #cef0c2;
  color:#1c3d0f;
    text-decoration: none;
}

.green-box-active{
    background: #509208;
    color: white;
}


.green-box-hover:hover{
    background: #509208;
    color: white;
}


.light-shadow {
  -webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
  -moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
  box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
}

.graph-container {

-webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
-moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);

background-color: #effaeb;
border: green 3px solid;    
}

#quick-buttons{
    overflow: hidden;
    width: 100%;
    margin-top:-.5rem;
}

#quick-buttons ul {
    list-style-type: none;
    list-style: none;
    padding: 0px;
}
#quick-buttons li{
  float: left;
  margin: 0 .2em 2px 1em;
}

#quick-buttons a{
  background: #509208;
  color: white;
  padding: .3em .5em;
  float: left;
  text-decoration: none;
  /* text-shadow: 0 1px 0 rgba(255,255,255,.5); */
  position: relative;
}

#quick-buttons a:hover{
  background: #cef0c2;
  color:#1c3d0f;
}

#quick-buttons a::before,
#quick-buttons a::after{
  content:'';
  position:absolute;
  top: 0;
  bottom: 0;
  width: 1em;
  background: #509208;
  transform: skew(-17deg);  
}

#quick-buttons li:first-of-type a::before {
  transform: none;  
}

#quick-buttons li:last-of-type a::after {
  transform: none;  
}


#quick-buttons a::before{

  left: -.5em;
  border-radius: 3px 0 0 3px;
}

#quick-buttons a:hover::before{
  background: #cef0c2;
}

#quick-buttons a::after{
  right: -.5em;   
  border-radius: 0 3px 3px 0;
}

#quick-buttons a:hover::after{
  background: #cef0c2;
}

#quick-buttons .current,
#quick-buttons .current:hover{
  font-weight: bold;
  background: none;
}

#quick-buttons .current::after,
#quick-buttons .current::before{
  content: normal;
}


.task-block {


-webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
-moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);

background-color: #effaeb;
border: green 1px solid;
border-radius:5px;
display: flex;

}

.task-icon {
  display: inline-block;
  width: 30px;
  background-color: #53b82e;
  color:white;
  padding: 3px;
  text-align:center;
}

.task-body {
  display: inline-block;
  padding: 3px;
  width: calc(100% - 30px - 5rem);


}

.task-body h4{
  font-size: 1rem;


}


.task-action {
  display: inline-block;
  background-color: #53b82e;
  width: 5rem;
  padding: 3px;
  vertical-align: middle;
  text-align: center;
}

h1 {
  color:#1d3d0f !important;
  text-shadow: 2px 2px 5px rgba(150, 150, 150, 0.65); 
  margin-left: -5px;
}
h1>i{
  color:#408f24;
}

h1.text-primary {
  color:#1d3d0f !important;
  margin-left: -5px;
}

h2.text-primary {
  color:#1d3d0f !important;
  text-shadow: 1px 1px 3px rgba(150, 150, 150, 0.65); 
  margin-left: -5px;
}
.alert-block{
  background-color: #effaeb !important;
}

.no-decoration {
  text-decoration: none;
  color: inherit;
}

.no-ddecoration:hover {
  text-decoration: none;
  color: inherit;
}

.rounded {
  border-radius: 10px;
  border: 1px silver solid;
}


/* //tabulka se změnami */
.change-log {
  border:1px solid white;
  width:100%;
  font-size: .7rem;
}

.change-log tr{
  border-top:0 !important;
  border:1px solid white;
}



.input-currency {
  border-radius:4px;
  border: 1px solid;
  background-color: #FFFFFF;
  padding-left:5px;
  padding-right:3px;
  margin-bottom: 4px;
  text-align: right;
  width:8rem;
}

.input-currency:after {
  content:" Kč";
}



.table-bordered tr:hover {

  background-color: #F3F8D0;
}

/* Tabulky se slevami  */
// .discount-table {
// }

.discount-edit-table {
  padding: .2rem;
}

.discount-label {
  margin-left:.2rem;
  margin-right:.2rem;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 6px;
  font-size: .9rem;
  background-color: silver;
  border: 1px solid grey;
}

.discount-edit-table input {
  border-radius:4px;
  border: 1px solid silver;
  background-color: #FFFFFF;
  padding-left:5px;
  margin-bottom: 4px;
  text-align: right;
}

// .discount-head {
// }

input .form-control{
  text-align: left !important;
}


/*  GALLERY  */

.elem, .elem * {
  box-sizing: border-box;
  margin: 0 !important; 
}
.elem {
  display: inline-block;
  font-size: 0;
  width: 100%;
  border: 0px solid transparent;
  border-bottom: none;
  background: #fff;
  padding: 0px;
  height: auto;
  background-clip: padding-box;
}
.elem > span {
  display: block;
  cursor: pointer;
  height: 0;
  padding-bottom: 70%;
  background-size: cover; 
  background-position: center center;
}


/*  background-color: #F3F8D0; */

.filters {
  border-radius:4px;
  margin-left: .2rem;
  margin-right: .2rem;
  background-color: #effaeb !important;
}


.filters:focus {
  background-color: #448e24 !important;
  color: white;
}

.filter-text:focus {
  background-color:white;
  color: white;
}
.filter-filled {
  background-color: #448e24 !important;
  color: white;
}

.filter-date{
  height:1.45rem;
}

.filter-text{
  height:1.45rem;
}

// .filter-select{
  
// }

.hover-bg:hover {
  background-color: #F3F8D0;
}


.popover {
  max-width:90% !important;

}
@media (max-width: 768px) {
.container-fluid {
  padding-left:2px !important;
  padding-right:2px !important;
}
}
.btn{
  margin-bottom: 4px;
  display:inline-block;
}

.customer-document {

-webkit-box-shadow: 2px 4px 13px 0px rgba(140,136,140,0.76);
-moz-box-shadow: 2px 4px 13px 0px rgba(140,136,140,0.76);
box-shadow: 2px 4px 13px 0px rgba(140,136,140,0.76);

border:1px grey solid;

}

.customer-document-header {
   background-color: silver;
   min-height: 5rem; 
  padding: 1rem;
  min-height:150px;
}

.customer-document-header h1{
  font-weight: 700;
  font-size: 1.5rem;
}
.customer-document-body {
  padding-left: .5rem;
  padding-right: .5rem;
  padding: 1rem;

}

.customer-document-form {
  padding-left: .5rem;
  padding-right: .5rem;
  padding: 1rem;

}

.customer-document-footer {
   background-color: silver;
   min-height: 3rem; 
  padding: 1rem;
  
}

.note-form {
  position: fixed;
  z-index:3;
  overflow: hidden;
  right:2rem;
  top:5px;
  width:30rem;
  padding: 1rem;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
transition: none !important;
/*  transition-timing-function: ease-out;
   transition: 0.3s;*/
   background-color: #e0ffaa;
   border: 2px green solid;
   border-radius:8px;
   box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
   text-align: left;
}
.note-form textarea{
   border: 1px green dotted;
   background-color: white;
   width: 100%;
   height: 9rem;
   font-size: .8rem;
   font-style: italic;

}


.fulltext-modal {
  background-color: #F3F8D0;
}

.fulltext-result {
  text-align:left; 
  border:1px dotted silver;
  margin:1px 0px 3px 0px;
  padding:3px 5px; 
}
.fulltext-icon {
}
.fulltext-desc {
  font-size:0.7rem;
  color:grey;
  padding-left:15px;
  line-height:0.8rem;
}

#fulltext_search_field {
  margin-top:-12px;
  border: 3px #FBA90A solid;
  padding: 10px 10px 10px 10px;
  font-size:1.2rem;
}

#fulltext-display {
  min-height: 40px; 
  margin-bottom: 30px;
}

.admin-buttons {
  float: right;
  
}
.custom input {
  padding: .3rem;
  border: lightgrey 1px solid;
  border-radius: 5px;
  width:100%;
}

.bg-box {
  background-color: #EDF56E;
  border: 1px dotted silver !important;
  padding: 5px;
}


.bg {
  background-color: #EDF56E;
}

.bg1 {
  background-color: #F3F8D0;
}

.text-color-2 {
  color: #EDF56E;
}

.text-color-1 {
  color: #F3F8D0;
}



.export-area {
}
.export {
  padding-top: 5px;
  padding-bottom: 5px;
}
.export-icon {
  background-color: #b5e7a0 !important;
  color:green;
  border-radius: 8px;
  border: 3px green solid;
}

.export-icon-a {
  text-decoration: none;

}

.export-icon-a:hover {
  text-decoration: none;

}

.export-icon:hover {
  background-color: yellow !important;
 box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
  text-decoration: none;

}


.export-icon h5{
  color:blue;
  min-height: 3rem;
}

.export .card-body {
  padding: 5px;
}
.export-card-header {
  background-color: #b5e7a0 !important;
  font-size: 1rem;
  line-height: 1rem;
  color:green;
  font-weight:700;
  text-transform: uppercase;
}
.export-card-body {

}



.report-area {
}
.report {
  padding-top: 5px;
  padding-bottom: 5px;
}
.report-icon {
  text-align: center;
  background-color: #b5e7a0 !important;
  color:green;
  border-radius: 8px;
  border: 3px green solid;
}

.report-icon-a {
  text-decoration: none;

}

.report-icon-a:hover {
  text-decoration: none;

}

.report-icon:hover {
  background-color: yellow !important;
 box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);  
  text-decoration: none;

}


.report-icon h5{
  color:blue;
  min-height: 3rem;
}

.report .card-body {
  padding: 5px;
}
.report-card-header {
  background-color: #b5e7a0 !important;
  font-size: 1rem;
  line-height: 1rem;
  color:green;
  font-weight:700;
  text-transform: uppercase;
}
.report-card-body {

}


.info-block {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin:.1rem;

  /* background-color: #EBEDEF; */
}

@media (max-width: 768px) {
.info-block {
  padding-left: .1rem;
  padding-right: .1rem;
}
}

.info-block h5{
  background-color: #e0ffaa;
  padding: 0px;
  padding-left: 1rem;
  font-weight:700;
  font-size: 1.3rem;
}
.info-block-title { /* titulní nadpis k bloku */
  background-color: #283B1B !important;
  color:white;

}
.info-block-title:hover, .info-block-title a:hover { /* titulní nadpis k bloku */
  background-color: #74B900 !important;
  color: #283B1B;
  text-decoration:none;
}

.info-block-title:hover { /* titulní nadpis k bloku */
-webkit-box-shadow: 3px 4px 5px -4px rgba(0,0,0,1);
-moz-box-shadow: 3px 4px 5px -4px rgba(0,0,0,1);
box-shadow: 3px 4px 5px -4px rgba(0,0,0,1);
}

.info-block-title a { /* titulní nadpis k bloku */
  color:white;
  padding: 5px;
  display: block;

}

.info-block div{
  padding-left: 1rem;
  font-size: 1rem;

}
.info-component {
  margin-top: 1rem;
  margin-bottom: 2rem;
  border: 1px green solid;
  border-radius: 10px;
  /*background-color: #effaeb; //light green*/

-webkit-box-shadow: 2px 2px 6px 0px rgba(40,54,37,0.27);
-moz-box-shadow: 2px 2px 6px 0px rgba(40,54,37,0.27);
box-shadow: 2px 2px 6px 0px rgba(40,54,37,0.27);

}

.info-component h2 {
  font-size: 1.3rem;
  font-weight: 700;
  padding-top: 1.5rem; 
}

.info-items {
  padding: .5rem;

}

.info-item {
  background-color: #f7f2ca;
  padding: .5rem;
  margin: .2rem;
}

.money-input {
  text-align: center;
  padding-right:30px;
}
.money-vat-select {
   background-color:#fdffb2;
   font-size: .7rem;
   border: 1px silver solid;
   padding: 0px;
   border-top-right-radius: 4px;
   border-bottom-right-radius: 4px;
}
.btn-primary {
  background-color: #62B118 !important;
  border: 1px !important;
}

.edit-form {
  border: 1px dotted silver;
  border-radius: 10px;
  padding: 6px;
}
.edit-title {
  font-size: 1.5rem;
  font-weight: 500;
}

h3.edit-title{
  font-size: 1.2rem !important;
  color: blue !important;
  font-weight: 500;
}


.edit-title em{
  font-size: 1.7rem;
  font-weight: 900;
}
.has-error {
  color:red;
}

#flash_error_message {
  float: right;
  position:fixed;
  top:3px;
  left:1rem;
  z-index:1000;
  min-width: 400px;
}

#flash_message {
  float: right;
  position:fixed;
  top:3px;
  left:1rem;
  z-index:1000;
}

.toolbox {
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 2px;
  padding-right: 5px;
  line-height: 1.7rem;
  margin-top: 1px;
  margin-bottom: 5px;

  background-color: #b5e7a0 !important;
  color:green;
  /*border-radius: 8px;*/
  border: 1px green solid;

-webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
-moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);


  /*background-color: #EDF56E;*/
  /*border: 1px silver solid;*/
  /*border-radius:3px;*/
}

.toolbox a {
  color:green;
}

.toolbox .btn {
  color:white;
}

.webpart-area {
}
.webpart {
  padding-top: 5px;
  padding-bottom: 5px;
}

.webpart>div.card {
  border: 3px #b5e7a0 solid;
  border-radius: 10px;

-webkit-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
-moz-box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
box-shadow: 1px 1px 4px 0px rgba(40,54,37,0.27);
}

.webpart>div:empty {
   display: none;
}
.webpart .card-body {
  padding: 5px;
}
.webpart-card-header {
  background-color: #b5e7a0 !important;
  font-size: 1rem;
  text-shadow: 1px 1px 5px rgba(150, 150, 150, 0.85);  
  line-height: 1rem;
  color:green;
  font-weight:700;
  text-transform: uppercase;
}
.webpart-card-body {

}
#CarsStatWebpart { 
  border: 5px red solid;
  background-color: red;
  
}
/*#UserInfoWebpart div{ 
  border: 0;
  background-color: transparent !important;
  
}
*/
#SHeadWebpart div{ 
  border: 0;
  border-radius: 10px;
  /*background-color: transparent !important; */
  /*background-color: #b5e7a0;*/
  padding: 5px;
  margin:0;
  
}


#VinWebpart>div{ 
  border: 0;
  /*background-color: transparent !important; */
  padding: 5px;
  margin:0;
  border: 3px #b5e7a0 solid;
  border-radius: 10px;

}

#EcoWebpart div{ 
  border: 0;
  /*background-color: transparent !important; */
  background-color: #EDF56E;
  padding: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin:0;
  
}


#PanelSellerWebpart div{ 
  border: 0;
  background-color: transparent !important;
  
}

.table-bordered {
  border-color: green !important;
}

.table-bordered th{
  background-color: #ebebeb; 
  /*#cff0c2*/
  border-color: green !important;
}



#bonus-table td{
  padding: 3px;
  border: 1px dotted silver;
}
#bonus-table input{
  width:80%;
}

/*table.dataTable tr.odd { background-color: white; }*/
/*table.dataTable tr.even { background-color: #f5f5f5; }*/
table.alternate-rows tr.even { background-color: #f0f0f0; }


.dataTable td{
  padding:3px !important;
  font-size: .9rem !important;
}

.dataTable.small-table td{
  font-size: .7rem !important;
}

.dataTable.small-table th{
  font-size: .8rem !important;
}


.simple-table {
  border:3px green solid; 
}

.simple-table tr{ 
  border-top: silver 1px solid;
  vertical-align: top;
}
.simple-table tr:hover{ 
    background-color: yellow;

}
.simple-table td{
  padding: 3px;  
  text-align: right;
  line-height: 1rem;
}

.simple-table-left td{
  padding: 3px;  
  text-align: left;
}

.simple-table th{
  background-color: #ffff99 ;

 /* background-color: #EDF56E; */
  padding: 5px;
  
}


/*

StatisticTable

každá má svoje ID
a pak úrovně
  thead - záhlaví
  tbody - všechny řádky, přitom


  .tstat - celá table
  .tgrN - N = úroveň záhlaví a 
  .tsum jsou součtové řádky
  .thead



*/
.tstat {
  border:3px green solid; 
}

.tstat td {
  padding: 5px;

}
.tstat tr:hover{ 
    background-color: yellow;
}


.tstat .date{
  text-align: center;
}

.tstat .datetime{
  text-align: center;
}

.tstat .currency {
  text-align: right;
  white-space:nowrap;
}


.tstat .currency:after {
  content:" Kč";
}

.tstat .currency:empty:after {
  content:"";
}

.tstat .percent {
  text-align: center;
  content:" %";
  white-space:nowrap;
}
.tstat .percent:after {
  content:" %";
}

.tstat .percent:empty:after {
  content:"";
}

.tstat .number {
  text-align: right;
}

.tstat th {
  padding: 5px; 
}

.tgr2 {
  background-color: white;
  color:green;
  font-size: .8rem;
  text-indent: 1rem;

}
.tgr1.thead, .tgr1.tsum{
  background-color: #ffffe6 ;
  color:green ;
  font-size: .9rem;
}


.thead.tgr2, .tsum.tgr2 {
  text-indent: .5rem;

}
.tgr1 {
  background-color: #ffffe6 ;
  color:green ;
  font-size: .9rem;
}



.tgr1.thead, .tgr1.tsum{
  background-color: #ffff99 ;
  color:green ;
  font-size: .9rem;
}


.thead.tgr2, .tsum.tgr2  {
  background-color: #ffffe6 ;
  color:green ;
  font-size: .75rem;
  text-indent: .3rem;
}

html {
  scroll-behavior: smooth;  
}

.section:hover .section-title {
    background-color:#8cde96;
}
.section-title {
    background-color:#d3f3d7;
    padding:.1rem;
    /* #448e24 */
    color:green;
    display: flex;
    justify-content:space-between;
    align-items:center;
    font-size:1.4rem;
    font-weight:50;
    padding-left:1rem;
    border-top:1px #E7E7E7 solid;
    border-bottom:1px #E7E7E7 solid;
}
.section-title:after{
    content: "\f078";
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    transition: all .5s ease;
    margin-right:1rem;
    color:silver;
}
.section-open .section-title:after{
    transform: rotate(180deg);
}
.section-body {
    padding:1rem;
    padding-top:.1rem;
    padding-bottom:.1rem;
    visibility: hidden;
    opacity: 0;
    transition: all .6s ease-in-out;
    max-height:0;
    overflow:hidden;
}
.section-open  .section-body {
    padding:1rem;
    visibility: visible;
    opacity: 1;
    max-height:1200px;
    overflow:auto;
    
}
.section-icon {
  position:absolute;
  right:5rem;
}
.section-icon>a{
  color:silver;
}
.line-box {
  display:grid; 
  grid-template-columns:40% 25% 25% 10%; 

  background-color:#f2f2f2;
  border:#e6e6e6 1px solid;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;     
  align-items: center;
}
.line-box:hover{
  background-color:#f3f8d0;
}
.bg-skoda {
  background-color:#448e24;
}
.bg-light-silver {
  background-color:#f2f2f2;
}
.text-skoda {
  color:#448e24;
}
.text-l{
  font-size:1.2rem;  
}

.text-m{
  font-size:1rem;  
}
.flex-grow{
  flex-grow: 1;
}
